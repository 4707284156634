import { Route } from 'react-router-dom';
import { PrivateRoute } from './PrivateRoute';
import { lazy } from 'react';
const Notifications = lazy(() => import('../components/Notifications'));

const Dashboard = lazy(() => import('../components/Dashboard'));
const Prescription = lazy(() => import('../components/Prescription'));
const SubmittedPrescription = lazy(() =>
  import('../components/Prescription/SubmittedPrescription'),
);
const NewPrescription = lazy(() => import('../components/Prescription/NewPrescription'));
const PreAuths = lazy(() => import('../components/PreAuths'));
const SubmittedPreAuth = lazy(() => import('../components/PreAuths/SubmittedPreAuth'));
const PreAuthDetails = lazy(() => import('../components/PreAuths/PreAuthDetails'));
const CreatePreAuth = lazy(() => import('../components/PreAuths/CreatePreAuth'));

const Claims = lazy(() => import('../components/Claims'));
const SubmittedClaim = lazy(() => import('../components/Claims/SubmittedClaim'));
const ClaimDetail = lazy(() => import('../components/Claims/ClaimDetail'));
const CreateNewClaim = lazy(() => import('../components/Claims/CreateClaim'));
const Reports = lazy(() => import('../components/Reports'));

const Payments = lazy(() => import('../components/Payments'));
const PaymentDetails = lazy(() => import('../components/PaymentDetails'));

const PrescriptionDetail = lazy(() => import('../components/Prescription/PrescriptionDetail'));

const Visits = lazy(() => import('../components/Visits'));
const CreateVisit = lazy(() => import('../components/Visits/CreateVisit'));
const VisitDetails = lazy(() => import('../components/Visits/VisitDetails'));

const Profile = lazy(() => import('../components/Profile'));
const Support = lazy(() => import('../components/Support'));
const Drugs = lazy(() => import('../components/Drugs'));


export const hospitals = (
  <>
    <Route
      path="/"
      element={
        <PrivateRoute>
          <Dashboard />
        </PrivateRoute>
      }
    />
    <Route
      path="/prescription"
      element={
        <PrivateRoute>
          <Prescription />
        </PrivateRoute>
      }
    />
    <Route
      path="/prescriptions/submitted"
      element={
        <PrivateRoute>
          <SubmittedPrescription />
        </PrivateRoute>
      }
    />
    <Route
      path="/prescription/:prescriptionNumber"
      element={
        <PrivateRoute>
          <PrescriptionDetail />
        </PrivateRoute>
      }
    />
    <Route
      path="/prescriptions/add"
      element={
        <PrivateRoute>
          <NewPrescription />
        </PrivateRoute>
      }
    />
    <Route
      path="/pre-auths"
      element={
        <PrivateRoute>
          <PreAuths />
        </PrivateRoute>
      }
    />
    <Route
      path="/pre-auths/submitted"
      element={
        <PrivateRoute>
          <SubmittedPreAuth />
        </PrivateRoute>
      }
    />
    <Route
      path="/pre-auths/:preauth_id"
      element={
        <PrivateRoute>
          <PreAuthDetails />
        </PrivateRoute>
      }
    />
    <Route
      path="/pre-auths/request"
      element={
        <PrivateRoute>
          <CreatePreAuth />
        </PrivateRoute>
      }
    />
    <Route
      path="/claims"
      element={
        <PrivateRoute>
          <Claims />
        </PrivateRoute>
      }
    />
    <Route
      path="/claims/submitted"
      element={
        <PrivateRoute>
          <SubmittedClaim />
        </PrivateRoute>
      }
    />
    <Route
      path="/claims/request"
      element={
        <PrivateRoute>
          <CreateNewClaim />
        </PrivateRoute>
      }
    />
    <Route
      path="/claims/:claim_id"
      element={
        <PrivateRoute>
          <ClaimDetail />
        </PrivateRoute>
      }
    />
    <Route
      path="/reports"
      element={
        <PrivateRoute>
          <Reports />
        </PrivateRoute>
      }
    />
    <Route
      path="/payments"
      element={
        <PrivateRoute>
          <Payments />
        </PrivateRoute>
      }
    />
    <Route
      path="/payments/:detail"
      element={
        <PrivateRoute>
          <PaymentDetails />
        </PrivateRoute>
      }
    />
    <Route
      path="/visits/"
      element={
        <PrivateRoute>
          <Visits />
        </PrivateRoute>
      }
    />
    <Route
      path="/visits/:visitID"
      element={
        <PrivateRoute>
          <VisitDetails />
        </PrivateRoute>
      }
    />
    <Route
      path="/visits/request/:memberNo"
      element={
        <PrivateRoute>
          <CreateVisit />
        </PrivateRoute>
      }
    />
    <Route
      path="/support"
      element={
        <PrivateRoute>
          <Support />
        </PrivateRoute>
      }
    />
    <Route path="/profile" element={<Profile />} />
    <Route
      path="/notifications"
      element={
        <PrivateRoute>
          <Notifications />{' '}
        </PrivateRoute>
      }
    />
    <Route
      path="/drugs"
      element={
        <PrivateRoute>
          <Drugs />
        </PrivateRoute>
      }
    />
  </>
);
